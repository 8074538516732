import React from "react";

const pagesRoutes = [
  {
    path: "/dashboard",
    component: React.lazy(() => import("./dashboard/dashboard")),
  },
  {
    path: "/customer",
    component: React.lazy(() => import("./customer/customerList")),
  },
  {
    path: "/courier",
    component: React.lazy(() => import("./courier/courier")),
  },
  {
    path: "/divisions",
    component: React.lazy(() => import("./locations/divisions")),
  },
  {
    path: "/districts",
    component: React.lazy(() => import("./locations/district")),
  },
  {
    path: "/upazila",
    component: React.lazy(() => import("./locations/upazila")),
  },

  {
    path: "/page-update",
    component: React.lazy(() => import("./singlePage/pageUpdate")),
  },
  {
    path: "/page-view",
    component: React.lazy(() => import("./singlePage/pagesView")),
  },
  {
    path: "/delivery-charge",
    component: React.lazy(() => import("./deliveryCharge/deliveryCharge")),
  },
  {
    path: "/pop-up-img",
    component: React.lazy(() => import("./media/popupImg")),
  },
  {
    path: "/slider-img",
    component: React.lazy(() => import("./media/sliders")),
  },
  {
    path: "/brand-img",
    component: React.lazy(() => import("./media/brandImg")),
  },
  {
    path: "/banner-text",
    component: React.lazy(() => import("./media/bannerText")),
  },
  {
    path: "/create-employee",
    component: React.lazy(() => import("./employee/createEmployee")),
  },
  {
    path: "/employee-list",
    component: React.lazy(() => import("./employee/employeeList.jsx")),
  },
  {
    path: "/employee-role/:employeeId",
    component: React.lazy(() => import("./employee/rolePermission")),
  },
  {
    path: "/employee-update/:empId",
    component: React.lazy(() => import("./employee/updateEmp")),
  },
  {
    path: "/profile",
    component: React.lazy(() => import("./general/profile")),
  },
  {
    path: "/password",
    component: React.lazy(() => import("./general/password")),
  },
  {
    path: "/create-hub",
    component: React.lazy(() => import("./hub/createHub")),
  },
  {
    path: "/hub-list",
    component: React.lazy(() => import("./hub/hubList")),
  },
  {
    path: "/update-hub/:hubId",
    component: React.lazy(() => import("./hub/updateHub")),
  },
  {
    path: "/create-hub-user",
    component: React.lazy(() => import("./HubUser/createHubUser")),
  },
  {
    path: "/hub-user-list",
    component: React.lazy(() => import("./HubUser/hubUserList")),
  },
  {
    path: "/hub-user-update/:userId",
    component: React.lazy(() => import("./HubUser/hubUserUpdate")),
  },
  {
    path: "/create-merchant",
    component: React.lazy(() => import("./merchant/createMerchant")),
  },
  {
    path: "/merchant-list",
    component: React.lazy(() => import("./merchant/merchantList")),
  },
  {
    path: "/merchant-update/:merchantId",
    component: React.lazy(() => import("./merchant/updateMerchant")),
  },
  {
    path: "/create-Rider",
    component: React.lazy(() => import("./Rider/createRider")),
  },
  {
    path: "/rider-List",
    component: React.lazy(() => import("./Rider/riderList")),
  },
  {
    path: "/rider-update/:riderId",
    component: React.lazy(() => import("./Rider/riderUpdate")),
  },
  {
    path: "/weight-charge",
    component: React.lazy(() => import("./weightCharge/createWeightCharge")),
  },
  {
    path: "/weight-charge-list",
    component: React.lazy(() => import("./weightCharge/weightChargeList")),
  },
  {
    path: "/weight-charge-update/:weightId",
    component: React.lazy(() => import("./weightCharge/weightChargeUpdate")),
  },
  {
    path: "/rider-charge",
    component: React.lazy(() => import("./Rider/riderCharge")),
  },
  {
    path: "/create-pickupman",
    component: React.lazy(() => import("./pickupMan/createPickupman")),
  },
  {
    path: "/pickupman-list",
    component: React.lazy(() => import("./pickupMan/pickupmanList")),
  },
  {
    path: "/pickupman-upate/:pickerId",
    component: React.lazy(() => import("./pickupMan/updatePickupman")),
  },
  {
    path: "/hub-user/parcelList",
    component: React.lazy(() => import("./parcel/allParcelList")),
  },
  {
    path: "/hub-user/pickup-parcel-list",
    component: React.lazy(() => import("./parcel/pickUpParcelList")),
  },
  {
    path: "/hub-user/pending-parcel-list",
    component: React.lazy(() => import("./parcel/pendingParcelList")),
  },
  {
    path: "/hub-user/process-parcel-list",
    component: React.lazy(() => import("./parcel/processParcelList")),
  },
  {
    path: "/create-parcel",
    component: React.lazy(() => import("./parcel/createParcel")),
  },
  {
    path: "/parcelList",
    component: React.lazy(() => import("./parcel/allParcelList")),
  },
  {
    path: "/parcel-update/:parcelId",
    component: React.lazy(() => import("./parcel/updateParcel")),
  },
  
  {
    path: "/deliver-to-other-hub",
    component: React.lazy(() => import("./hub-to-hub/deliverToOtherHub")),
  },
  {
    path: "/all-transfer-list",
    component: React.lazy(() => import("./hub-to-hub/allTransferList")),
  },
  {
    path: "/update-process-parcel/:parcelId",
    component: React.lazy(() => import("./parcel/updateProcessingParcel")),
  },
  {
    path: "/update-deliver-process-parcel/:parcelId",
    component: React.lazy(() => import("./parcel/updateDeliveryProcessParcel")),
  },
  {
    path: "/all-receive-list",
    component: React.lazy(() => import("./hub-to-hub/receivingParcelList")),
  },
  {
    path: "/deliver-parcel",
    component: React.lazy(() => import("./parcel/deliverParcel")),
  },
  {
    path: "/deliver-process-parcels",
    component: React.lazy(() => import("./parcel/deliverProcessList")),
  },
  {
    path: "/image-banner",
    component: React.lazy(() => import("./banners/imageBanner")),
  },
  {
    path: "/text-banner",
    component: React.lazy(() => import("./banners/textBanner")),
  },
  {
    path: "/payment-requests",
    component: React.lazy(() => import("./paymentRequest/paymentRequestList")),
  },
  {
    path: "/process-parcel",
    component: React.lazy(() => import("./parcel/findParcelById")),
  },
  {
    path: "/pickup-parcel-history",
    component: React.lazy(() => import("./history/pickupHistory")),
  },
  {
    path: "/delivery-parcel-history",
    component: React.lazy(() => import("./history/deliveryHistory")),
  },
  {
    path: "/transfer-parcel-history",
    component: React.lazy(() => import("./history/transferHistory")),
  },
  {
    path: "/create-category",
    component: React.lazy(() => import("./category/createCategory")),
  },
  {
    path: "/category-list",
    component: React.lazy(() => import("./category/categoryList")),
  },
  {
    path: "/update-category/:categoryId",
    component: React.lazy(() => import("./category/updateCategory")),
  },
  {
    path: "/printInvoice",
    component: React.lazy(() => import("./parcel/printParcelInvoice.jsx")),
  },
  {
    path: "/update-password",
    component: React.lazy(() => import("./updatePassword/updatePassword")),
  },
  {
    path: "/pending-payment-list",
    component: React.lazy(() => import("./payment/pendingPaymentList")),
  },
  {
    path: "/processing-payment-list",
    component: React.lazy(() => import("./payment/processingPaymentList")),
  },
  {
    path: "/payment-receive-history",
    component: React.lazy(() => import("./payment/paymentHistory")),
  },
  {
    path: "/merchant-list-payment",
    component: React.lazy(() => import("./payment/payToMerchant/merchantList")),
  },
  {
    path: "/merchant-wise-parcel/:merchantId/:name",
    component: React.lazy(() => import("./payment/payToMerchant/merchantParcelList")),
  },
  {
    path: "/merchant-payment-history",
    component: React.lazy(() => import("./payment/payToMerchant/merchantPaymentHistory")),
  },
  {
    path: "/return-merchant-list",
    component: React.lazy(() => import("./returnParcel/returnMerchantList")),
  },
 
  {
    path: "/merchant-wise-return-parcel/:merchantId/:name",
    component: React.lazy(() => import("./returnParcel/returnParcelList")),
  },
  {
    path: "/return-process-list",
    component: React.lazy(() => import("./returnParcel/returnProcessList")),
  },
  {
    path: "/print-return-invoice/:id",
    component: React.lazy(() => import("./returnParcel/printReturnInvoice")),
  },
  {
    path: "/print-payment-invoice/:id",
    component: React.lazy(() => import("./payment/payToMerchant/merchantPaymentInvoice")),
  },
  {
    path: "/return-history",
    component: React.lazy(() => import("./returnParcel/returnHistory")),
  },
  {
    path: "/pending-return-list",
    component: React.lazy(() => import("./returnParcel/pendingReturnParcel")),
  },
  {
    path: "/create-bank",
    component: React.lazy(() => import("./addBank/createBank")),
  },
  {
    path: "/bank-list",
    component: React.lazy(() => import("./addBank/bankList")),
  },
  {
    path: "/update-bank/:bankId",
    component: React.lazy(() => import("./addBank/updateBank")),
  },
  {
    path: "/parcel-list-download",
    component: React.lazy(() => import("./parcel/allParcelListDownload")),
  },
  {
    path: "/merchant-pending-invoice",
    component: React.lazy(() => import("./payment/payToMerchant/merchantInvoieList")),
  },
  {
    path: "/search-parcel",
    component: React.lazy(() => import("./parcel/searchParcel")),
  },
  {
    path: "/sortingParcel",
    component: React.lazy(() => import("./sorting/sortingPage")),
  },
  {
    path: "/sack-list",
    component: React.lazy(() => import("./sorting/sackList")),
  },
  {
    path: "/thermal-print",
    component: React.lazy(() => import("./sorting/thermalPrinting")),
  },
  {
    path: "/receive-parcel",
    component: React.lazy(() => import("./receiveSack/receiveSack")),
  },
  {
    path: "/inHouse-parcel",
    component: React.lazy(() => import("./parcel/inHouseParcel")),
  },
  {
    path: "/sack-history",
    component: React.lazy(() => import("./sorting/sackHistory")),
  },
 
];

export default pagesRoutes;
