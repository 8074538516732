// import { authRoles } from "./auth/authRoles";

export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: "dashboard",
  },

  {
    label: "Hub",
    type: "label",
  },
  {
    name: "HUB",
    icon: "home",
    children: [
      {
        name: "Create Hub",
        path: "/create-hub",
        iconText: "VO",
      },
      {
        name: "Hub List",
        path: "/hub-list",
        iconText: "OL",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
     
    ],
  },
  {
    name: "HUB USER",
    icon: "person",
    children: [
      {
        name: "Create User",
        path: "/create-hub-user",
        iconText: "VO",
      },
      {
        name: "User List",
        path: "/hub-user-list",
        iconText: "OL",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
    ],
  },
  {
    label: "MANAGE PARCEL",
    type: "label",
  },
  {
    name: "Parcel",
    icon: "folder",
    children: [
      {
        name: "Create Parcel",
        icon: "hail",
        path: "/create-parcel",
      },
      {
        name: "All Parcel List",
        icon: "moped",
        path: "/parcelList",
        options:[{name:'Edit',active:false},{name:'Status',active:false}]
      },
      {
        name: "Parcel Download",
        icon: "download",
        path: "/parcel-list-download",
      },
      {
        name: "In House Parcel List",
        icon: "list",
        path: "/inHouse-parcel",
        options:[{name:'Edit',active:false}]
      },
      {
        name: "Search Parcel",
        icon: "search",
        path: "/search-parcel",
        options:[{name:'Status',active:false}]
      },
    ],
  },
  {
    name: "Pickup Parcel",
    icon: "moped",
    path: "/hub-user/parcelList",
    children: [
      {
        name: "Ready To Pickup",
        path: "/hub-user/pending-parcel-list",
        iconText: "VO",
        options:[{name:'Edit',active:false},{name:'Delete',active:false},{name:'Pickup',active:false}]
      },
      {
        name: "Assigned Parcels",
        path: "/hub-user/process-parcel-list",
        iconText: "VO",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
      {
        name: "Processing List",
        path: "/process-parcel",
        iconText: "VO",
        options:[{name:'Edit',active:false}]
      },
      {
        name: "Pickup History",
        path: "/pickup-parcel-history",
        iconText: "VO",
      },
    ],
  },
  {
    name: "Parcel Sack",
    icon: "folder",
    children: [
      {
        name: "Sort & Create Sack",
        icon: "sort",
        path: "/sortingParcel",
        options:[{name:'Create',active:false}]
      },
      {
        name: "Sack List",
        path: "/sack-list",
        icon: "checklist",
        options:[{name:'Status',active:false}]
      },
      {
        name: "Sack History",
        path: "/sack-history",
        icon: "history",
      },
    ],
  },
  // {
  //   name: "Branch Transfer",
  //   icon: "folder",
  //   children: [
  //     {
  //       name: "Transferred Parcel's",
  //       path: "/all-transfer-list",
  //       iconText: "NP",
  //     },
  //     {
  //       name: "Transfer to Other Hub",
  //       path: "/deliver-to-other-hub",
  //       iconText: "PL",
  //       options:[{name:'Edit',active:false},{name:'Transfer',active:false}]
  //     },
  //     {
  //       name: "Transfer History",
  //       path: "/transfer-parcel-history",
  //       iconText: "PL",
  //     },
  //   ],
  // },
  {
    name: "Receive Parcel",
    icon: "folder",
    children: [
      {
        name: "Receive Parcel's",
        path: "/all-receive-list",
        iconText: "NP",
        options:[{name:'Status',active:false}]
      },
    ],
  },
  {
    name: "Deliver Parcel",
    icon: "folder",
    children: [
      {
        name: "Pending Delivery List",
        path: "/deliver-parcel",
        iconText: "PL",
        options:[{name:'Edit',active:false},{name:'Deliver',active:false}]
      },
      {
        name: "Process Delivery List",
        path: "/deliver-process-parcels",
        iconText: "NP",
        options:[{name:'Edit',active:false},{name:'Status',active:false}]
      },
      {
        name: "Delivery History",
        path: "/delivery-parcel-history",
        iconText: "NP",
      },
    ],
  },
  {
    name: "Return Parcel",
    icon: "folder",
    children: [
      {
        name: "Pending Return list",
        path: "/pending-return-list",
        iconText: "PL",
        options:[{name:'Return',active:false}]
      },
      {
        name: "Merchant list",
        path: "/return-merchant-list",
        iconText: "PL",
        // options:[{name:'Edit',active:false}]
      },

      {
        name: "Invoice History",
        path: "/return-history",
        iconText: "PL",
      },
    ],
  },
  {
    label: "MANAGE PAYMENT",
    type: "label",
  },
  {
    name: "Payment",
    icon: "money",
    children: [
      {
        name: "Pending Payment List",
        iconText: "PL",
        path: "/pending-payment-list",
        options:[{name:'Payment',active:false}]
      },
      {
        name: "Processing Payment List",
        iconText: "PL",
        path: "/processing-payment-list",
        options:[{name:'Status',active:false}]

      },
      {
        name: "Payment Receive History",
        iconText: "PL",
        path: "/payment-receive-history",
      },
    ],
  },
  {
    name: "Pay To Merchant",
    icon: "person",
    children: [
      {
        name: "Merchant List",
        path: "/merchant-list-payment",
        iconText: "NP",
        options:[{name:'Create Invoice',active:false}]
      },
      {
        name: "Merchant Payment Invoice",
        path: "/merchant-pending-invoice",
        iconText: "NP",
        options:[{name:'Make Payment',active:false}]
      },
      {
        name: "Merchant Payment History",
        path: "/merchant-payment-history",
        iconText: "NP",
      },
    ],
  },

  {
    label: "MERCHANT",
    type: "label",
  },

  {
    name: "Merchant",
    icon: "person",
    children: [
      {
        name: "Create Merchant",
        path: "/create-merchant",
        iconText: "NP",
      },
      {
        name: "Merchant List",
        path: "/merchant-list",
        iconText: "PL",
        options:[{name:'Edit',active:false}]
      },
    ],
  },
  {
    label: "RIDER & PICKUP MAN",
    type: "label",
  },
  {
    name: "Rider",
    icon: "moped",
    children: [
      {
        name: "Create Rider",
        path: "/create-Rider",
        iconText: "NP",
      },
      {
        name: "Rider List",
        path: "/rider-List",
        iconText: "PL",
        options:[{name:'Edit',active:false}]
      },
    ],
  },
  {
    name: "Pickup Man",
    icon: "hail",
    children: [
      {
        name: "Create Pickup Man",
        path: "/create-pickupman",
        iconText: "NP",
      },
      {
        name: "Pickup man List",
        path: "/pickupman-list",
        iconText: "PL",
        options:[{name:'Edit',active:false}]
      },
    ],
  },
  {
    name: "Employee",
    icon: "person",
    children: [
      {
        name: "Create Employee",
        path: "/create-employee",
        iconText: "OL",
      },
      {
        name: "Employee List",
        path: "/employee-list",
        iconText: "VO",
        options:[{name:'Role',active:false}]
      },
    ],
  },

  {
    label: "SETTINGS",
    type: "label",
  },

  {
    name: "Category",
    icon: "category",
    children: [
      {
        name: "Create Category",
        path: "/create-category",
        iconText: "NP",
      },
      {
        name: "Category List",
        path: "/category-list",
        iconText: "PL",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
    ],
  },
  {
    name: "Weight Charge",
    icon: "scale",
    children: [
      {
        name: "Create Weight Charge",
        path: "/weight-charge",
        iconText: "NP",
      },
      {
        name: "Weight Charge List",
        path: "/weight-charge-list",
        iconText: "PL",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
    ],
  },

  {
    name: "Location",
    icon: "map",
    children: [
      {
        name: "District",
        path: "/districts",
        iconText: "VO",
      },
      {
        name: "Area",
        path: "/upazila",
        iconText: "OL",
        // options:[{name:'Add Area',active:false}]
      },
    ],
  },
  {
    name: "Rider/Delivery Charge",
    icon: "folder",
    children: [
      {
        name: "Delivery Charge",
        path: "/delivery-charge",
        icon: "paid",
      },
      {
        name: "Rider Charge",
        path: "/rider-charge",
        icon: "paid",
      },
    ],
  },
  {
    name: "Bank",
    icon: "money",
    children: [
      {
        name: "Add Bank",
        path: "/create-bank",
        iconText: "VO",
      },

      {
        name: "Bank List",
        path: "/bank-list",
        iconText: "OL",
        options:[{name:'Edit',active:false},{name:'Delete',active:false}]
      },
    ],
  },

  {
    name: "Banner",
    icon: "BrokenImage",
    children: [
      {
        name: "Text Banner",
        path: "/text-banner",
        iconText: "VO",
        options:[{name:'Delete',active:false}]
      },

      {
        name: "Image Banner",
        path: "/image-banner",
        iconText: "OL",
        options:[{name:'Delete',active:false}]
      },
    ],
  },
  {
    name: "Password",
    icon: "password",
    children: [
      {
        name: "Update Password",
        path: "/update-password",
        iconText: "VO",
      },
    ],
  },
  
 
];
